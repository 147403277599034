var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('CCardHeader',[_c('strong',[_vm._v("Casa Funeraria")])]),_c('CCard',[_c('CCardBody',[_c('div',[_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('strong',[_vm._v("Nome")]),_c('br'),_c('br'),_c('CInput',{model:{value:(_vm.item.nome),callback:function ($$v) {_vm.$set(_vm.item, "nome", $$v)},expression:"item.nome"}})],1),_c('CCol',{attrs:{"sm":"12"}},[_c('strong',[_vm._v("Descrizione")]),_c('br'),_c('br'),_c('CCard',{staticClass:"customCard"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.item.descrizione),expression:"item.descrizione"}],attrs:{"rows":"20","cols":"80"},domProps:{"value":(_vm.item.descrizione)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.item, "descrizione", $event.target.value)}}})])],1)],1),_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('br'),_c('div',[_c('UploadMultiplePhotoAndPreview',{attrs:{"title":"Casa Funeraria","subtitle":"Casa Funeraria","img":_vm.item.foto},on:{"getPhoto":(data) => {
                        _vm.item.foto = data;
                      }}})],1)])],1),_c('CButton',{attrs:{"type":"submit","size":"lg","color":"primary"},on:{"click":() => {
                  if (this.$route.params.id !== undefined) {
                    this.updateCasaFuneraria(this.item);
                  } else {
                    this.createCasaFuneraria(this.item);
                  }
                  this.$router.push({ name: 'listaCaseFunerarie' });
                }}},[_c('CIcon',{attrs:{"name":"cil-save"}}),_vm._v(" Salva ")],1)],1)])],1)],1)],1),(_vm.item.id !== undefined)?_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('CCardHeader',[_c('strong',[_vm._v("Stanze Funerarie")])]),_c('CCard',{staticClass:"customCard"},[_c('CCardBody',[_vm._l((_vm.items),function(stanza){return _c('div',{key:stanza.id},[_c('CCardHeader',[_c('CButton',{staticClass:"custom-button-save-faq",attrs:{"type":"submit","size":"md","color":"primary"},on:{"click":function($event){return _vm.updateStanzaFuneraria(stanza)}}},[_c('CIcon',{attrs:{"name":"cil-save"}}),_vm._v(" Salva ")],1),_c('CButton',{staticClass:"custom-button-destroy-faq",attrs:{"type":"submit","size":"md","color":"danger"},on:{"click":function($event){return _vm.deleteStanzaFuneraria(stanza)}}},[_c('CIcon',{attrs:{"name":"cil-trash"}}),_vm._v(" Rimuovi ")],1),_c('br'),_c('br'),_c('h5',[_vm._v("Nome Stanza")]),_c('CInput',{model:{value:(stanza.nome_stanza),callback:function ($$v) {_vm.$set(stanza, "nome_stanza", $$v)},expression:"stanza.nome_stanza"}}),_c('h5',[_vm._v("Logo Stanza")]),_c('UploadSinglePhotoAndPreview',{attrs:{"img":stanza.foto_stanza,"title":"Carica qui il logo della stanza funeraria","subtitle":"Logo Stanza Funeraria"},on:{"getPhoto":(photo) => {
                    stanza.foto_stanza = photo;
                  }}}),_c('br'),_c('h5',[_vm._v("Descrizione Stanza")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(stanza.descrizione),expression:"stanza.descrizione"}],attrs:{"rows":"20","cols":"100"},domProps:{"value":(stanza.descrizione)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(stanza, "descrizione", $event.target.value)}}}),_c('br'),_c('br'),_c('h5',[_vm._v("Foto Stanza")]),_c('UploadMultiplePhotoAndPreview',{attrs:{"img":stanza.img_stanza,"title":"Stanza"},on:{"getPhoto":(photo) => {
                    stanza.img_stanza = photo;
                  }}}),_c('br'),_c('br')],1),_c('br')],1)}),_c('CButton',{attrs:{"type":"submit","size":"md","color":"info"},on:{"click":function($event){return _vm.createStanzaFuneraria(_vm.item)}}},[_c('CIcon',{attrs:{"name":"cil-plus"}}),_vm._v(" Aggiungi ")],1)],2)],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }